<template>
    <div id="page-user-list">

        <vx-card class="mb-base">
            <vs-tabs v-if="loaded">

                <template v-for="(item) in products">
                    <vs-tab :key="item.id" :label="item.name">
                        <div class="tab-text">
                            <vs-table stripe pagination max-items="10" search :data="item.data">

                                <template slot="header">
                                    <div class="flex">
                                        <h3 class="mr-4">{{item.name}} Ticket</h3>
                                        <h3><vs-chip color="primary">{{ item.data.length }}</vs-chip></h3>
                                    </div>
                                </template>

                                <template slot="thead">
                                    <vs-th>Ticket Number</vs-th>
                                    <vs-th>Date</vs-th>
                                    <vs-th>Time</vs-th>
                                    <vs-th>Deadline</vs-th>
                                    <vs-th>Product</vs-th>
                                    <vs-th>Customer</vs-th>
                                    <vs-th>Priority</vs-th>
                                </template>

                                <template slot-scope="{data}">
                                    <vs-tr :key="indextr" v-for="(tr, indextr) in data">

                                        <vs-td :data="data[indextr].ticket_number">
                                            <a class="cursor-pointer" @click="detailTicket(data[indextr].complain_id)">{{ data[indextr].ticket_number }}</a>
                                        </vs-td>

                                        <vs-td :data="data[indextr].tanggal">
                                        {{ data[indextr].tanggal }}
                                        </vs-td>

                                        <vs-td :data="data[indextr].waktu">
                                        {{ data[indextr].waktu }}
                                        </vs-td>

                                        <vs-td :data="data[indextr].deadline">
                                        <vs-chip transparent color="danger">{{ data[indextr].deadline.substring(0, 10) }}</vs-chip>
                                        </vs-td>

                                        <vs-td :data="(data[indextr].product == null) ? '-' : data[indextr].product.name">
                                        {{ (data[indextr].product == null) ? '-' : data[indextr].product.name }}
                                        </vs-td>

                                        <vs-td :data="(data[indextr].company == null) ? '-' : data[indextr].company.company_name">
                                        {{ (data[indextr].company == null) ? '-' : data[indextr].company.company_name }}
                                        </vs-td>

                                        <vs-td :data="data[indextr].priority">
                                        {{ data[indextr].priority }}
                                        </vs-td>

                                    </vs-tr>
                                </template>

                            </vs-table>
                        </div>
                    </vs-tab>
                </template>

                <!-- <vs-tab label="Global Roamer">
                    <div class="tab-text">
                        <vs-table stripe pagination max-items="10" search :data="tableData.troubleTicket">

                            <template slot="header">
                                <div class="flex">
                                    <h3 class="mr-4">Global Roamer Ticket</h3>
                                    <h3><vs-chip color="primary">2</vs-chip></h3>
                                </div>
                            </template>

                            <template slot="thead">
                                <vs-th>Ticket Number</vs-th>
                                <vs-th>Date</vs-th>
                                <vs-th>Time</vs-th>
                                <vs-th>Deadline</vs-th>
                                <vs-th>Product</vs-th>
                                <vs-th>Customer</vs-th>
                                <vs-th>Priority</vs-th>
                            </template>

                            <template slot-scope="{data}">
                                <vs-tr :key="indextr" v-for="(tr, indextr) in data">

                                    <vs-td :data="data[indextr].number">
                                    {{ data[indextr].number }}
                                    </vs-td>

                                    <vs-td :data="data[indextr].date">
                                    {{ data[indextr].date }}
                                    </vs-td>

                                    <vs-td :data="data[indextr].time">
                                    {{ data[indextr].time }}
                                    </vs-td>

                                    <vs-td :data="data[indextr].deadline">
                                    <vs-chip transparent color="danger">{{ data[indextr].deadline }}</vs-chip>
                                    </vs-td>

                                    <vs-td :data="data[indextr].product">
                                    {{ data[indextr].product }}
                                    </vs-td>

                                    <vs-td :data="data[indextr].customer">
                                    {{ data[indextr].customer }}
                                    </vs-td>

                                    <vs-td :data="data[indextr].priority">
                                    {{ data[indextr].priority }}
                                    </vs-td>

                                </vs-tr>
                            </template>

                        </vs-table>
                    </div>
                </vs-tab>
                <vs-tab label="Keynote Sigos">
                    <vs-table stripe pagination max-items="10" search :data="tableData.troubleTicket">

                        <template slot="header">
                            <div class="flex">
                                <h3 class="mr-4">Keynote Sigos Ticket</h3>
                                <h3><vs-chip color="primary">2</vs-chip></h3>
                            </div>
                        </template>

                        <template slot="thead">
                            <vs-th>Ticket Number</vs-th>
                            <vs-th>Date</vs-th>
                            <vs-th>Time</vs-th>
                            <vs-th>Deadline</vs-th>
                            <vs-th>Product</vs-th>
                            <vs-th>Customer</vs-th>
                            <vs-th>Priority</vs-th>
                        </template>

                        <template slot-scope="{data}">
                            <vs-tr :key="indextr" v-for="(tr, indextr) in data">

                                <vs-td :data="data[indextr].number">
                                {{ data[indextr].number }}
                                </vs-td>

                                <vs-td :data="data[indextr].date">
                                {{ data[indextr].date }}
                                </vs-td>

                                <vs-td :data="data[indextr].time">
                                {{ data[indextr].time }}
                                </vs-td>

                                <vs-td :data="data[indextr].deadline">
                                <vs-chip transparent color="danger">{{ data[indextr].deadline }}</vs-chip>
                                </vs-td>

                                <vs-td :data="data[indextr].product">
                                {{ data[indextr].product }}
                                </vs-td>

                                <vs-td :data="data[indextr].customer">
                                {{ data[indextr].customer }}
                                </vs-td>

                                <vs-td :data="data[indextr].priority">
                                {{ data[indextr].priority }}
                                </vs-td>

                            </vs-tr>
                        </template>

                    </vs-table>
                </vs-tab>
                <vs-tab label="Orion Telecom">
                    <vs-table stripe pagination max-items="10" search :data="tableData.troubleTicket">

                        <template slot="header">
                            <div class="flex">
                                <h3 class="mr-4">Orion Telecom Ticket</h3>
                                <h3><vs-chip color="primary">2</vs-chip></h3>
                            </div>
                        </template>

                        <template slot="thead">
                            <vs-th>Ticket Number</vs-th>
                            <vs-th>Date</vs-th>
                            <vs-th>Time</vs-th>
                            <vs-th>Deadline</vs-th>
                            <vs-th>Product</vs-th>
                            <vs-th>Customer</vs-th>
                            <vs-th>Priority</vs-th>
                        </template>

                        <template slot-scope="{data}">
                            <vs-tr :key="indextr" v-for="(tr, indextr) in data">

                                <vs-td :data="data[indextr].number">
                                {{ data[indextr].number }}
                                </vs-td>

                                <vs-td :data="data[indextr].date">
                                {{ data[indextr].date }}
                                </vs-td>

                                <vs-td :data="data[indextr].time">
                                {{ data[indextr].time }}
                                </vs-td>

                                <vs-td :data="data[indextr].deadline">
                                <vs-chip transparent color="danger">{{ data[indextr].deadline }}</vs-chip>
                                </vs-td>

                                <vs-td :data="data[indextr].product">
                                {{ data[indextr].product }}
                                </vs-td>

                                <vs-td :data="data[indextr].customer">
                                {{ data[indextr].customer }}
                                </vs-td>

                                <vs-td :data="data[indextr].priority">
                                {{ data[indextr].priority }}
                                </vs-td>

                            </vs-tr>
                        </template>

                    </vs-table>
                </vs-tab>
                <vs-tab label="PI Works">
                    <vs-table stripe pagination max-items="10" search :data="tableData.troubleTicket">

                        <template slot="header">
                            <div class="flex">
                                <h3 class="mr-4">PI Works Ticket</h3>
                                <h3><vs-chip color="primary">2</vs-chip></h3>
                            </div>
                        </template>

                        <template slot="thead">
                            <vs-th>Ticket Number</vs-th>
                            <vs-th>Date</vs-th>
                            <vs-th>Time</vs-th>
                            <vs-th>Deadline</vs-th>
                            <vs-th>Product</vs-th>
                            <vs-th>Customer</vs-th>
                            <vs-th>Priority</vs-th>
                        </template>

                        <template slot-scope="{data}">
                            <vs-tr :key="indextr" v-for="(tr, indextr) in data">

                                <vs-td :data="data[indextr].number">
                                {{ data[indextr].number }}
                                </vs-td>

                                <vs-td :data="data[indextr].date">
                                {{ data[indextr].date }}
                                </vs-td>

                                <vs-td :data="data[indextr].time">
                                {{ data[indextr].time }}
                                </vs-td>

                                <vs-td :data="data[indextr].deadline">
                                <vs-chip transparent color="danger">{{ data[indextr].deadline }}</vs-chip>
                                </vs-td>

                                <vs-td :data="data[indextr].product">
                                {{ data[indextr].product }}
                                </vs-td>

                                <vs-td :data="data[indextr].customer">
                                {{ data[indextr].customer }}
                                </vs-td>

                                <vs-td :data="data[indextr].priority">
                                {{ data[indextr].priority }}
                                </vs-td>

                            </vs-tr>
                        </template>

                    </vs-table>
                </vs-tab>
                <vs-tab label="VSS Monitoring">
                    <vs-table stripe pagination max-items="10" search :data="tableData.troubleTicket">

                        <template slot="header">
                            <div class="flex">
                                <h3 class="mr-4">VSS Monitoring Ticket</h3>
                                <h3><vs-chip color="primary">2</vs-chip></h3>
                            </div>
                        </template>

                        <template slot="thead">
                            <vs-th>Ticket Number</vs-th>
                            <vs-th>Date</vs-th>
                            <vs-th>Time</vs-th>
                            <vs-th>Deadline</vs-th>
                            <vs-th>Product</vs-th>
                            <vs-th>Customer</vs-th>
                            <vs-th>Priority</vs-th>
                        </template>

                        <template slot-scope="{data}">
                            <vs-tr :key="indextr" v-for="(tr, indextr) in data">

                                <vs-td :data="data[indextr].number">
                                {{ data[indextr].number }}
                                </vs-td>

                                <vs-td :data="data[indextr].date">
                                {{ data[indextr].date }}
                                </vs-td>

                                <vs-td :data="data[indextr].time">
                                {{ data[indextr].time }}
                                </vs-td>

                                <vs-td :data="data[indextr].deadline">
                                <vs-chip transparent color="danger">{{ data[indextr].deadline }}</vs-chip>
                                </vs-td>

                                <vs-td :data="data[indextr].product">
                                {{ data[indextr].product }}
                                </vs-td>

                                <vs-td :data="data[indextr].customer">
                                {{ data[indextr].customer }}
                                </vs-td>

                                <vs-td :data="data[indextr].priority">
                                {{ data[indextr].priority }}
                                </vs-td>

                            </vs-tr>
                        </template>

                    </vs-table>
                </vs-tab> -->
            </vs-tabs>
        </vx-card>
    </div>
</template>

<script>
import tableData from '../tableData.js'

export default {
    data() {
        return {
            loaded: false,
            products: [],
            tableData
        }
    },
    methods : {
        detailTicket (id) {
            this.$router.push({
                name: 'trouble-ticket-detail', 
                params: { id: this.$secure.encrypt(id) }
            });
        },
        getProducts () {
            const self = this
            self.products = []

            this.$store.dispatch('product/fetchProducts')
            .then(res => {
                const data = res.data.data
                data.forEach(element => {
                    self.getTicketData(element.id, element.name, data.length)
                });
                
            })
            .catch(err => {
                if (!err.response) {
                    // network error
                    err.response = {
                        data: {
                        message: 'Server error'
                        }
                    };
                }
                this.$vs.notify({
                title:'Opps something error',
                text: err.response.data.message,
                color:'danger'})
            })
            // .finally(() => {
            //     this.onLoaded()
            // })
        },
        getTicketData (pid, pname, plength) {
            const self = this
            this.$store.dispatch('ticket/fetchTicketsByProduct', { 
                pid: pid
            })
            .then(res => {
                const data = res.data.data

                self.products.push({
                    id: pid,
                    name: pname,
                    data: data
                })

                if(self.products.length == plength) {
                    self.onLoaded()
                    console.log(plength, self.loaded)
                }
            })
            .catch(err => {
                if (!err.response) {
                    // network error
                    err.response = {
                        data: {
                        message: 'Server error'
                        }
                    };
                }
                this.$vs.notify({
                title:'Opps something error',
                text: err.response.data.message,
                color:'danger'})
            })
        },
        onLoaded(){
            this.loaded = true;
            this.$vs.loading.close()
        },
        loading () {
            this.loaded = false;

            this.$vs.loading({
                type: 'border',
                color: 'primary',
                text: `Wait a minute, It's getting data.`
            })
        },
    },
    created() {
        this.loading()
        this.getProducts()
    }
}
</script>
